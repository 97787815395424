
import React from "react"
import { Link } from 'gatsby'
import Header from '../components/header'
import Footer from '../components/footer'
import Copyright from '../components/copyright'

const NotFoundPage = () => (
  <div className="page">
    <Header />
    <div className="layout">
      <div className="layout__container">
        <div className="layout__wide">
          <nav className="breadcrumb breadcrumb__top">
            <p><Link to="/">Home</Link> &nbsp;&rsaquo;&nbsp; <strong>404</strong></p>
          </nav>
        </div>
      </div>
    </div>
    <div className="layout">
        <div className="layout__container">
            <div className="layout__wide">
                <div className="body flex flex__space-around">
                    <div className="block__60 text">
                        <h1>Page Not Found</h1>
                        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    <Copyright />
  </div>
)

export default NotFoundPage
